var $iEn1Z$apolloclientcore = require("@apollo/client/core");
var $iEn1Z$apolloclientlinkerror = require("@apollo/client/link/error");
var $iEn1Z$apollouploadclient = require("apollo-upload-client");
var $iEn1Z$uuid = require("uuid");

function $parcel$exportWildcard(dest, source) {
  Object.keys(source).forEach(function(key) {
    if (key === 'default' || key === '__esModule' || dest.hasOwnProperty(key)) {
      return;
    }

    Object.defineProperty(dest, key, {
      enumerable: true,
      get: function get() {
        return source[key];
      }
    });
  });

  return dest;
}
function $parcel$export(e, n, v, s) {
  Object.defineProperty(e, n, {get: v, set: s, enumerable: true, configurable: true});
}



var $9c00f1ab7df2a211$exports = {};

$parcel$export($9c00f1ab7df2a211$exports, "doQuery", () => $9c00f1ab7df2a211$export$7ba66d7797a3c6e7);
$parcel$export($9c00f1ab7df2a211$exports, "doMutation", () => $9c00f1ab7df2a211$export$ac8ca5fd8e2fd2cc);
$parcel$export($9c00f1ab7df2a211$exports, "GraphQLClient", () => $9c00f1ab7df2a211$export$b0f268a6eae2cdf1);


const $9c00f1ab7df2a211$export$7ba66d7797a3c6e7 = async (client, query, variables, fetchPolicy)=>{
    return await client.query({
        query: query,
        variables: variables,
        fetchPolicy: fetchPolicy
    });
};
const $9c00f1ab7df2a211$export$ac8ca5fd8e2fd2cc = async (client, mutation, variables, fetchPolicy)=>{
    return await client.mutate({
        mutation: mutation,
        variables: variables,
        fetchPolicy: fetchPolicy
    });
};
const $9c00f1ab7df2a211$var$makeFetchFn = (fn, client, query, variables, fetchPolicy, autoQuery)=>{
    let _loading = false;
    let _internalData;
    let _loadingCount = 0;
    const loadingListener = new Map();
    const dataListener = new Map();
    const errorListener = new Map();
    const fetchData = async (variables, fetchPolicy)=>{
        return (await fn(client, query, variables, fetchPolicy)).data;
    };
    const isLoading = ()=>_loading;
    const loadingCount = ()=>_loadingCount;
    const onLoadingChange = (fn)=>{
        const _id = (0, $iEn1Z$uuid.v4)();
        loadingListener.set(_id, fn);
        return ()=>{
            loadingListener.delete(_id);
        };
    };
    const onLoadingChangeOnce = (fn)=>{
        const _id = (0, $iEn1Z$uuid.v4)();
        let _lastState = true;
        loadingListener.set(_id, (state, stateChangeCount)=>{
            if (_lastState === false) loadingListener.delete(_id);
            else {
                _lastState = state;
                fn(state, stateChangeCount);
            }
        });
        return ()=>{
            loadingListener.delete(_id);
        };
    };
    const updateLoadingState = (state)=>{
        _loading = state;
        if (_loading) _loadingCount = _loadingCount + 1;
        loadingListener.forEach((fn)=>{
            fn(_loading, _loadingCount);
        });
    };
    const onData = (fn)=>{
        const _id = (0, $iEn1Z$uuid.v4)();
        dataListener.set(_id, fn);
        return ()=>{
            dataListener.delete(_id);
        };
    };
    const onDataOnce = (fn)=>{
        const _id = (0, $iEn1Z$uuid.v4)();
        dataListener.set(_id, (args)=>{
            fn(args);
            dataListener.delete(_id);
        });
        return ()=>{
            dataListener.delete(_id);
        };
    };
    const onError = (fn)=>{
        const _id = (0, $iEn1Z$uuid.v4)();
        errorListener.set(_id, fn);
        return ()=>{
            errorListener.delete(_id);
        };
    };
    const onErrorOnce = (fn)=>{
        const _id = (0, $iEn1Z$uuid.v4)();
        errorListener.set(_id, (args)=>{
            fn(args);
            errorListener.delete(_id);
        });
        return ()=>{
            errorListener.delete(_id);
        };
    };
    const parentParams = {
        variables: variables,
        fetchPolicy: fetchPolicy
    };
    const fetch = async (variables, fetchPolicy)=>{
        try {
            updateLoadingState(true);
            const _data = await fetchData(variables || parentParams.variables, fetchPolicy || parentParams.fetchPolicy);
            _internalData = _data;
            dataListener.forEach((fn)=>{
                try {
                    fn(_data);
                } catch (err) {
                    console.log(err);
                }
            });
            return _data;
        } catch (err) {
            errorListener.forEach((fn)=>{
                try {
                    fn(err);
                } catch (err) {
                    console.log(err);
                }
            });
            throw new Error(err);
        } finally{
            updateLoadingState(false);
        }
    };
    if (autoQuery) setTimeout(()=>{
        fetch(parentParams.variables, parentParams.fetchPolicy);
    });
    return {
        data: ()=>_internalData,
        fetch: fetch,
        onData: onData,
        onDataOnce: onDataOnce,
        onError: onError,
        onErrorOnce: onErrorOnce,
        onLoadingChange: onLoadingChange,
        onLoadingChangeOnce: onLoadingChangeOnce,
        isLoading: isLoading,
        loadingCount: loadingCount
    };
};
class $9c00f1ab7df2a211$export$b0f268a6eae2cdf1 {
    constructor(client){
        this.client = client;
    }
    query(query, config = {}) {
        return $9c00f1ab7df2a211$var$makeFetchFn($9c00f1ab7df2a211$export$7ba66d7797a3c6e7, this.client, query, config.variables, config.fetchPolicy, config.autoFire);
    }
    mutation(mutation, config = {}) {
        return $9c00f1ab7df2a211$var$makeFetchFn($9c00f1ab7df2a211$export$ac8ca5fd8e2fd2cc, this.client, mutation, config.variables, config.fetchPolicy);
    }
}


$parcel$exportWildcard(module.exports, $iEn1Z$apolloclientcore);
$parcel$exportWildcard(module.exports, $iEn1Z$apolloclientlinkerror);
$parcel$exportWildcard(module.exports, $iEn1Z$apollouploadclient);
$parcel$exportWildcard(module.exports, $9c00f1ab7df2a211$exports);


